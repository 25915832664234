<template>
  <MevcutLink
    :to="`/delivering/storeTypes/${type.id}`"
    class="px-1 w-full"
  >
    <div
      class="h-[90px] md:h-[115px] flex flex-col justify-center hover:bg-nav_color/20  rounded-[16px] bg-nav_color/10 px-2 pt-2 mt-2"
    >
      <div class="flex justify-center  h-[60px]">
        <img
          :src="type.image"
          class="h-full"
        />
      </div>
      <span class="text-center text-nav_color font-bold">
        {{ t(type.name) }}
      </span>
    </div>
  </MevcutLink>
</template>

<script setup lang="ts">
defineProps<{
  type: {
    name: string;
    color: string;
    id: number;
    image: string;
}
}>()
const { t } = useI18n()
</script>
