<template>
  <CustomHeader class="min-h-screen">
    <template #header>
      <div class="h-full w-full relative">
        <PlacesDialouge
          class="absolute -translate-y-1/2 translate-x-1/2 top-1/2 right-1/2"
        />
        <LayoutNavbarPlatformNotification class="absolute -translate-y-1/2 top-1/2 rtl:left-2 ltr:right-2" />
      </div>
    </template>
    <template #body>
      <div class="mevcut-container">
        <HomeAdMainSwiper
          :ads="{
            error: getErrors,
            items: getAnnouncements?.primaryDelivery
          }"
          class="mt-2"
        />
        <div class="flex flex-wrap justify-between  my-6">
          <DeliveryStoreTypesLevel1
            :type="typesLevels.level1"
            class="w-1/2 md:w-3/12"
          />
          <div class="w-1/2 md:w-4/12 flex flex-col">
            <DeliveryStoreTypesLevel2
              v-for="(type, j) in typesLevels.level2"
              :key="`DeliveryStoreTypesLevel2-${j}`"
              class="mb-2"
              :type="type"
            />
          </div>
          <div class="w-full md:w-5/12 flex flex-col">
            <DeliveryStoreTypesLevel3 class="w-full" :type="typesLevels.level3" />
            <div class="flex">
              <DeliveryStoreTypesLevel4
                v-for="(type, j) in typesLevels.level4"
                :key="`DeliveryStoreTypesLevel4r-${j}`"
                :type="type"
                class="w-full"
              />
            </div>
          </div>
        </div>
        <LazyHomeAdSecondarySwiper
          :ads="{ error: getErrors, items: getAnnouncements?.secondaryDelivery }"
        />
      </div>
    </template>
  </CustomHeader>
</template>

<script setup lang="ts">
import getStoresTypeLevels from '~/components/delivery/StoreTypes/StoresTypeLevels'
const { getErrors, getAnnouncements } = useAnnouncementStore()
const typesLevels = getStoresTypeLevels()
const { url } = useDomainHost()
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(
    url + '/delivering',
    t('at_yours'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)
useHead({
  title: t('at_yours'),
  link: [useSeoCanonical(url + '/delivering')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('at_yours'), url + '/delivering')
    ])
  ]
})
</script>
