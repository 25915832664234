<template>
  <MevcutLink
    :to="`/delivering/storeTypes/${type.id}`"
    class="px-1"
  >
    <div class="h-60 flex flex-col rounded-[16px] bg-yellow-600/10 p-2  hover:bg-yellow-600/20">
      <div
        class="flex justify-between items-center p-1 bg-yellow-500/20 rounded-[16px]"
      >
        <span class="flex-1 text-center text-yellow-500 font-bold">
          {{ t(type.name) }}
        </span>
        <div class="bg-yellow-400 rounded-[8px]">
          <v-icon
            icon="mdi mdi-chevron-left"
            size="22"
            class="ltr:rotate-180 text-white"
          />
        </div>
      </div>

      <div class="flex justify-end sm:justify-center h-36 mt-auto">
        <img :src="type.image" class="h-full" />
      </div>
    </div>
  </MevcutLink>
</template>

<script setup lang="ts">

defineProps<{
  type: any
}>()
const { t } = useI18n()
</script>
