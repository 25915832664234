<template>
  <MevcutLink
    :to="`/delivering/storeTypes/${type.id}`"
    class="px-1 w-full"
  >
    <div
      class="h-[115px] flex flex-col px-2 pt-2  rounded-[16px] "
      :style="`background-color: ${props.type.color}33`"
    >
      <div
        class="flex justify-between items-center p-1 rounded-[16px] "
        :style="`background-color: ${props.type.color}1A`"
      >
        <span
          class="flex-1 text-center"
          :style="`color: ${props.type.color}`"
        >

          {{ t(type.name) }}
        </span>
        <div class="rounded-[8px] p-px" :style="`background-color: ${props.type.color}`">
          <v-icon
            icon="mdi mdi-chevron-left"
            size="22"
            class="ltr:rotate-180 text-white"
          />
        </div>
      </div>
      <div class="flex justify-center h-[60px] mt-auto">
        <img :src="type.image" class="h-full aspect-[2.3]" />
      </div>
    </div>
  </MevcutLink>
</template>

<script setup lang="ts">

const props = defineProps<{
  type: {
    name: string;
    color: string;
    id: number;
    image: string;
},
}>()
const { t } = useI18n()
</script>
