<template>
  <MevcutLink
    :to="`/delivering/storeTypes/${type.id}`"
    class="px-1 w-full"
  >
    <div class="h-[115px] flex items-center rounded-[16px]  px-2 pt-2  bg-opacity-10 hover:bg-opacity-20" :class="bg">
      <div
        class="flex flex-1 justify-between h-8 items-center p-1 bg-opacity-20 rounded-[16px]"
        :class="bg"
      >
        <span class="flex-1 text-center  font-bold" :style="`color: ${props.type.color}`">
          {{ t(type.name) }}
        </span>
        <div class="p-px  rounded-[8px]" :class="bg">
          <v-icon
            icon="mdi mdi-chevron-left"
            size="22"
            class="ltr:rotate-180 text-white"
          />
        </div>
      </div>
      <div class="flex justify-center h-full mt-auto">
        <img
          :src="type.image"
          class="h-full "
        />
      </div>
    </div>
  </MevcutLink>
</template>

<script setup lang="ts">
const props = defineProps<{
  type: {
    name: string;
    color: string;
    id: number;
    image: string;
}
}>()
const bg = 'bg-blue-200'
const { t } = useI18n()

</script>
